import React from 'react';
import { useLocation } from 'react-router';
import { SIZE_UNITS } from '../consts/spot.consts';

export const isElementInViewport = (el) => {
    var rect = el.boundingClientRect;
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
};

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

export const getOnlyAlpha = (str = '') => {
    if (typeof str !== 'string') {
        return '';
    }
    return /^[a-zA-Z0-9 ]+$/.test(str.trim());
};

export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isEllipsisActive = (e) => {
    const temp = e.cloneNode(true);

    temp.style.position = 'fixed';
    temp.style.overflow = 'visible';
    temp.style.whiteSpace = 'nowrap';
    temp.style.visibility = 'hidden';

    e.parentElement.appendChild(temp);

    try {
        const fullWidth = temp.getBoundingClientRect().width;
        const displayWidth = e.getBoundingClientRect().width;
        return fullWidth > displayWidth;
    } finally {
        temp.remove();
    }
};

export const shuffle = (array) => {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

export const getRandTestGroup = () => (getRandomInt(0, 2) == 0 ? false : true);

export const showSize = (size, sizeUnits) => {
    const spotSize = parseFloat(size);
    if (SIZE_UNITS[sizeUnits] === SIZE_UNITS.ACRES) {
        if (spotSize < 1) {
            return `${spotSize} acres`;
        }
        if (spotSize == 1) {
            return '1 acre';
        }
        if (spotSize > 1 && spotSize < 10) {
            return `${Math.round(spotSize * 10) / 10} acres`;
        }
        if (spotSize >= 10) {
            return `${parseInt(spotSize)} acres`;
        }
    } else {
        return `${spotSize} ${SIZE_UNITS[sizeUnits] ? SIZE_UNITS[sizeUnits] : 'acres'}`;
    }
};

export const scrollToElement = (ele, isMobile = true, headerOffset = 56) => {
    const addedOffset = isMobile ? 0 : 80;
    const elementPosition = ele.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset - addedOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
    });
};

export const includesAll = (arr = [], target = []) => target.every((v) => arr.includes(v));
